.stt-and-tts-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 75px;
  gap: 10px;
}

.stt-and-tts-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.mic-icon {
  width: 24px;
}
.mic-off-icon {
  width: 24px;
}
.speaker-icon {
  width: 24px;
}
.speaker-off-icon {
  width: 24px;
}
.mic-icon path {
  fill: white;
}
.mic-off-icon path {
  fill: white;
}
.speaker-icon path {
  fill: white;
}
.speaker-off-icon path {
  fill: white;
}

.bg-gray {
  background-color: #999;
}

.notification-bubble {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: yellow;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  color: black;
  white-space: nowrap;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  animation: fadeInOut 4s ease;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.stt-and-tts-button.active {
  background-color: #4caf50;
}

.stt-and-tts-button svg {
  width: 24px;
  height: 24px;
}

.noti-message {
  min-width: 220px;
  text-align: center;
  position: absolute;
  top: 70px;
  left: 115%;
  background-color: #ffd700;
  color: #333;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  white-space: nowrap;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}
