.text-button {
  background: rgb(0, 191, 129);
  border-radius: 12px;
  height: 32px;
  cursor: pointer;
  padding: 6px 18px;
  color: white;
  margin-top: 8px;
  font-size: 12px;
  position: relative;
}

.text-align-center {
  text-align: center;
}

.horizontal-divider {
  border-top: 2px solid rgb(0, 191, 129);
  margin: 10px 0;
}

.arrow-icon path {
  fill: white;
}

.bg-blue {
  background-color: #2a2fcf;
}

.bg-gray {
  background-color: #b3b3b3;
}
