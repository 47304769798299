.react-datepicker__time{
    overflow : auto;
    height: 300px;

}
.react-datepicker__time-list{
    text-align: center;
    font-size: 20px;
    line-height: 50px;
}

.react-datepicker__time-list-item{
    border-bottom: 2px solid gray;
}

.react-datepicker__time-list-item:hover{
    background-color: gray;
}

.react-datepicker__navigation.react-datepicker__navigation--next {
    right: 0px;
}

.react-datepicker__time-container {
    width: 100px
}

.react-datepicker{
    display: flex;
}