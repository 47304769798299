.dropdown-options:focus-within {
  /*display: block;*/
  visibility: visible;
  overflow: scroll;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown:hover .dropdown-options {
  /*display: block;*/
  visibility: visible;
  overflow: scroll;
}

.placeholder_test:focus ~ .dropdown-options {
  /*display: block;*/
  visibility: visible;
  overflow: scroll;
}

.dropdown-options {
  /*display: none;*/
  transition-duration: 0.1s;
  visibility: hidden;
  position: absolute;
  overflow: auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
  max-height: 200px;
  width: 240px;
  z-index: 50;
  top: 75px;
}

.dropdown-options span {
  display: block;
  color: #000000;
  padding: 5px;
  text-decoration: none;
  padding: 20px 40px;
}

.dropdown-options span:hover {
  color: #0a0a23;
  background-color: #ddd;
  border-radius: 5px;
}

.chat-dropdown-options:focus-within {
  /*display: block;*/
  visibility: visible;
  overflow: scroll;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown:hover .chat-dropdown-options {
  /*display: block;*/
  visibility: visible;
  overflow: scroll;
}

.placeholder_test:focus ~ .chat-dropdown-options {
  /*display: block;*/
  visibility: visible;
  overflow: scroll;
}

.chat-dropdown-options {
  /*display: none;*/
  transition-duration: 0.1s;
  position: absolute;
  overflow: auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
  max-height: 200px;
  width: 240px;
  z-index: 50;
  top: 75px;
  left: 30px;
}

.chat-dropdown-options.chat {
  visibility: visible;
}
.chat-dropdown-options span {
  display: block;
  color: #000000;
  padding: 5px;
  text-decoration: none;
  padding: 20px 40px;
}

.chat-dropdown-options span:hover {
  color: #0a0a23;
  background-color: #ddd;
  border-radius: 5px;
}
