.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1020;
}

.modal-body {
  position: absolute;
  top: 300px;
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
  width: 450px;
  max-width: 90%;
  transition: all 0.3s ease;
}

.modal-title {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #34495e;
}

.modal-content {
  padding: 24px;
  font-size: 16px;
  color: #2c3e50;
  text-align: center;
  line-height: 1.5;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  background-color: #ecf0f1;
  border-top: 1px solid #bdc3c7;
}

.modal-button {
  flex: 1;
  max-width: 150px;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.modal-button.confirm-button {
  background-color: #27ae60;
  color: white;
}

.modal-button.cancel-button {
  background-color: #e74c3c;
  color: white;
}

.modal-button.confirm-button:hover,
.modal-button.cancel-button:hover {
  background-color: #2ecc71;
  transform: translateY(-2px);
}

.modal-button.cancel-button:hover {
  background-color: #c0392b;
}

.modal-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15) inset;
}
