.loading {
	position: absolute;
	top: 0px;
	left: 0px;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	background-color: #FFFFFF;

	z-index: 10;
}

.circle {
	width: 100px;
	height: 100px;

	border-top: 3px solid #01a781;
	border-left: 3px solid #01a781;
	border-radius: 50%;

	animation: spin 2s linear infinite forwards;
}

@keyframes spin {
	0% {
		rotate: 0deg;
	}

	50% {
		rotate: 180deg;
	}

	100% {
		rotate: 360deg;
	}
}