.tip {
  position: absolute;
  background-color: rgb(229, 246, 253);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 14px;
  color: rgb(1, 67, 97);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid black;
  z-index: 1;
}

.tip::before,
.tip::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

/* Arrow with background color */
.tip::before {
  z-index: 1;
}

/* Arrow for the border */
.tip::after {
  z-index: 0;
}

/* Top positions */
.tip-top-left::before,
.tip-top-center::before,
.tip-top-right::before {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent rgb(229, 246, 253) transparent;
  bottom: 100%;
  position: absolute;
  z-index: 9999;
}

.tip-top-left::after,
.tip-top-center::after,
.tip-top-right::after {
  border-width: 0 11px 11px 11px;
  border-color: transparent transparent black transparent;
  bottom: 100%;
}

/* Top Left */
.tip-top-left::before {
  left: 10%;
  transform: translateX(-10%);
}

.tip-top-left::after {
  left: 10%;
  transform: translateX(-10%);
}

/* Top Center */
.tip-top-center::before {
  left: 50%;
  transform: translateX(-50%);
}

.tip-top-center::after {
  left: 50%;
  transform: translateX(-50%);
}

/* Top Right */
.tip-top-right::before {
  right: 10%;
  transform: translateX(10%);
}

.tip-top-right::after {
  right: 10%;
  transform: translateX(10%);
}

/* Bottom positions */
.tip-bottom-left::before,
.tip-bottom-center::before,
.tip-bottom-right::before {
  border-width: 10px 10px 0 10px;
  border-color: rgb(229, 246, 253) transparent transparent transparent;
  top: 100%;
}

.tip-bottom-left::after,
.tip-bottom-center::after,
.tip-bottom-right::after {
  border-width: 11px 11px 0 11px;
  border-color: black transparent transparent transparent;
  top: 100%;
}

/* Bottom Left */
.tip-bottom-left::before {
  left: 10%;
  transform: translateX(-10%);
}

.tip-bottom-left::after {
  left: 10%;
  transform: translateX(-10%);
}

/* Bottom Center */
.tip-bottom-center::before {
  left: 50%;
  transform: translateX(-50%);
}

.tip-bottom-center::after {
  left: 50%;
  transform: translateX(-50%);
}

/* Bottom Right */
.tip-bottom-right::before {
  right: 10%;
  transform: translateX(10%);
}

.tip-bottom-right::after {
  right: 10%;
  transform: translateX(10%);
}

/* Left positions */
.tip-left-top::before,
.tip-left-center::before,
.tip-left-bottom::before {
  border-width: 10px 10px 10px 0;
  border-color: transparent rgb(229, 246, 253) transparent transparent;
  right: 100%;
}

.tip-left-top::after,
.tip-left-center::after,
.tip-left-bottom::after {
  border-width: 11px 11px 11px 0;
  border-color: transparent black transparent transparent;
  right: 100%;
}

/* Left Top */
.tip-left-top::before {
  top: 10%;
  transform: translateY(-10%);
}

.tip-left-top::after {
  top: 10%;
  transform: translateY(-10%);
}

/* Left Center */
.tip-left-center::before {
  top: 50%;
  transform: translateY(-50%);
}

.tip-left-center::after {
  top: 50%;
  transform: translateY(-50%);
}

/* Left Bottom */
.tip-left-bottom::before {
  bottom: 10%;
  transform: translateY(10%);
}

.tip-left-bottom::after {
  bottom: 10%;
  transform: translateY(10%);
}

/* Right positions */
.tip-right-top::before,
.tip-right-center::before,
.tip-right-bottom::before {
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgb(229, 246, 253);
  left: 100%;
}

.tip-right-top::after,
.tip-right-center::after,
.tip-right-bottom::after {
  border-width: 11px 0 11px 11px;
  border-color: transparent transparent transparent black;
  left: 100%;
}

/* Right Top */
.tip-right-top::before {
  top: 10%;
  transform: translateY(-10%);
}

.tip-right-top::after {
  top: 10%;
  transform: translateY(-10%);
}

/* Right Center */
.tip-right-center::before {
  top: 50%;
  transform: translateY(-50%);
}

.tip-right-center::after {
  top: 50%;
  transform: translateY(-50%);
}

/* Right Bottom */
.tip-right-bottom::before {
  bottom: 10%;
  transform: translateY(10%);
}

.tip-right-bottom::after {
  bottom: 10%;
  transform: translateY(10%);
}
