.wrapper {
  z-index: 2;
}

/* layout  css */
.slide_main_list {
  height: 450px !important;
}
/**/

.swiper {
  width: 100%;
  height: 100%;
}

.slide_main_list {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.slide_main_list img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center;
}
/**/

.page_main .only_wide {
  display: block;
}
.page_main .only_mob {
  display: none;
}

@media all and (max-width: 768px) {
  .page_main .only_wide {
    display: none;
  }
  .page_main .only_mob {
    display: block;
  }
}

.job_popular_anchor {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.job_popular_item .box_badge {
  gap: 5px !important;
}
