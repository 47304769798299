.quiz-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.block-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
}

.block-grid.preview {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;

  transition: 0.3s !important;

  pointer-events: none;
}

.block-grid.preview.under {
  opacity: 0;
}

.block-grid-timer {
  height: 10px;
  border-radius: 100px;

  margin-top: 20px;

  animation: timer 5s;
}

@keyframes timer {
  0% {
    width: 100%;
    background-color: hsl(100, 100%, 50%);
  }

  100% {
    width: 0%;
    background-color: hsl(0, 100%, 50%);
  }
}

.block-card {
  width: 100%;
  border: 1px solid black;
  background-color: white;

  transition: 0.3s;

  cursor: pointer;
}

.block-card::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.block-card:hover {
  background-color: cornflowerblue;
  opacity: 0.7;
}

.block-card.selected {
  background-color: cornflowerblue;
}

.button-wrap {
  justify-content: space-between;
}

.button-wrap > .right {
  gap: 8px;
}

.quiz-button {
  width: 200px;
}

.quiz-template {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.quiz {
  position: relative;
  display: flex;
  gap: 40px;
  min-height: 400px;
}

.quiz-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quiz-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quiz-assets {
  position: relative;
  width: 480px;
  flex-shrink: 0;
}

.quiz-answer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.picture-quiz-image {
  border: 1px solid black;
}

.picture-quiz-table th {
  background-color: black;
  color: white;
  font-weight: bold;

  padding: 8px 20px;
}

.picture-quiz-table th:last-child {
  width: 100px;
}

.picture-quiz-table thead tr {
  border-bottom: 4px solid white;
}

.picture-quiz-table td {
  padding: 8px 20px;
  border: 1px solid white;
}

.picture-quiz-table tr:nth-child(1n) {
  background-color: #ddd;
}

.picture-quiz-table tr:nth-child(2n) {
  background-color: #ccc;
}

.picture-quiz-table input {
  border: transparent;
  background-color: transparent;
  outline: none;
}

.emotion-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;

  width: 300px;
  gap: 20px;
}

.emotion-buttons button {
  transition: 0.3s;
}

.card-quiz-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.card-quiz-grid-item {
  border: 1px solid black;
  padding: 4px;
  transition: 0.3s;
  height: 160px;
}

.card-quiz-grid-item:hover {
  background-color: #6495edaa;
}

.card-quiz-grid-item.selected {
  background-color: #6495ed;
}

.card-quiz-grid-item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.card-quiz-row {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card-quiz-row p {
  position: absolute;
  left: 0;
  margin: 0;
}

.card-quiz-category-text {
  width: 100px;
}

.card-quiz-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-quiz-button {
  width: 64px;
  height: 64px;
  border: 1px solid black;
}

.card-quiz-button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.notice-template {
  background-color: rgb(229, 246, 253);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  color: rgb(1, 67, 97);
  border: 1px solid black;
}

.notice-template-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
