.game-timer {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.timer-display {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 480px;
  height: 24px;
  gap: 5px;
}

.timer-text {
  font-size: 18px;
  color: black;
  font-weight: 900;
}

.timer-icon {
  width: 24px;
  height: 24px;
}
